const Home = () => {
  return (
    <div className="home centered">
      <p>
        Therefore, confess your sins to one another, and pray for one another so
        that you may be healed. The effective prayer of a righteous man can
        accomplish much.
        <small>James 5:16</small>
      </p>
    </div>
  );
};

export default Home;
